<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-banner with-route-bar">
      <div class="items">
        <div
          class="item"
          :style="{
            'background-image': `url(${require('@/assets/img/sky.jpg')})`
          }"
        >
          <span>
            Добро пожаловать на&nbsp;борт!
            <em>Услуги • Развлечения • Шоппинг</em>
          </span>

          <picture>
            <source
              srcset="@/assets/img/plane-s.png"
              media="(max-width: 374px)"
            />
            <source
              srcset="@/assets/img/plane-s.png"
              media="(max-width: 413px)"
            />
            <source
              srcset="@/assets/img/plane-s.png"
              media="(max-width: 767px)"
            />
            <source
              srcset="@/assets/img/plane-s.png"
              media="(max-width: 1023px)"
            />
            <source
              srcset="@/assets/img/plane.png"
              media="(max-width: 1279px)"
            />
            <source
              srcset="@/assets/img/plane.png"
              media="(max-width: 1439px)"
            />
            <source
              srcset="@/assets/img/plane.png"
              media="(max-width: 1599px)"
            />
            <img src="@/assets/img/plane.png" alt="" />
          </picture>
        </div>
      </div>
      <div class="shadow"></div>
    </section>

    <section class="section section-default section-text">
      <div class="container m-container-only-s">
        <h1>{{ $t("pages.pay-form") }}</h1>

        <h2>Вот тут мы идем на экран оплаты</h2>

        <div class="buttons">
          <input
            type="submit"
            @click.prevent="$router.push({ name: 'UpgradeSeat' })"
            class="submit small"
            value="Далее"
          />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "PayForm",
  title() {
    return this.$t("pages.pay-form");
  }
};
</script>

<style scoped></style>
